<section class="" id="galleria">
  <div class="container flex px-2 pt-10 mx-auto lg:pt-16 max-w-7xl">
    <div class="flex flex-wrap w-full mb-10">
      <div class="mx-auto text-center lg:text-left lg:ml-0">
        <h1
          class="mx-auto text-2xl font-semibold w-72 text-button sm:text-4xl lg:text-6xl font-cambon"
        >Galleria</h1>
      </div>
      <div class="w-full lg:hidden" />
      <div
        class="flex justify-center py-6 mx-auto lg:py-0 lg:justify-end place-items-center w-72 lg:mr-0 lg:ml-auto"
      >
        <img class="pr-2" src="./static/ig.svg" alt="instagramlogo" />
        <p class="text-base text-button font-ptsans lg:w-2/3">
          josefiinamikkeli
        </p>
      </div>
    </div>
  </div>
  <div class="container flex flex-wrap px-5 pb-10 mx-auto max-w-7xl">
    <div class="flex sm:border-2 max-h-110 border-secondary">
      <ul
        class="juicer-feed sm:right-4 sm:bottom-4"
        data-per="6"
        data-feed-id="josefiinamikkeli"
      />
    </div>
  </div>
  <div
    class="container flex flex-wrap px-5 pt-4 pb-10 mx-auto lg:pb-16 max-w-7xl"
  >
    <a
      target="_blank"
      href="https://www.instagram.com/josefiinamikkeli"
      class="px-6 py-3 mx-auto text-xs font-bold uppercase border-2 sm:text-sm bg-secondary text-button hover:bg-btnhover"
      >seuraa instagramissa</a
    >
  </div>
</section>

<style global>:global(.juicer-feed) :global(h1.referral) {
  display: none !important;
}

:global(.juicer-ad) {
  display: none !important;
}

:global(.feed-item) {
  max-height: 300px;
}

:global(.feed-item) :global(img) {
  object-fit: cover !important;
  min-height: 300px !important;
}

:global(html) {
  overflow: scroll;
}

:global(::-webkit-scrollbar) {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}</style>
